import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../components/recipe.css'

export default function Recipe({ data }) {
  const recipe = data.toml
  const ingreds = recipe.ingredients || recipe.canonical_ingredients
  const instructs = recipe.instructions || recipe.canonical_instructions
  const ingredients = ingreds.map(ingred => <li>{ingred}</li>)
  const instructions = instructs.map(instruct => <li>{instruct}</li>)
  var table;
  if (recipe.cooking_time_table) {
    var cooking_time_table = recipe.cooking_time_table.map((row, i) => {
      if (i === 0) {
        return <tr><th>{row[0]}</th><th>{row[1]}</th><th>{row[2]}</th></tr>
      } else {
        return <tr><td>{row[0]}</td><td>{row[1]}</td><td>{row[2]}</td></tr>
      }
    })
    table = <table>{cooking_time_table}</table>
  }
  const references = recipe.references.map(ref => <li><a href={ref}>{ref}</a></li>)

  return (
    <Layout>
      <SEO title={recipe.title} />
      <h1>{recipe.title}</h1>
      <div class="recipe">
        <div class="column ingredients">
          <h2>Ingredients</h2>
          <ul>{ingredients}</ul>
        </div>
        <div class="column instructions">
          <h2>Instructions</h2>
          <ol>{instructions}</ol>
        </div>
      </div>
      {table}
      <ul>{references}</ul>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    toml(fields: { slug: { eq: $slug } }) {
      title
      substitutions
      canonical_ingredients
      canonical_instructions
      equipment
      ingredients
      instructions
      references
      shortcuts
      storage
      cooking_time_table
    }
  }
`
